import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"

import AboutMe from "../components/sections/AboutMe"
import Education from "../components/sections/Education"
import Hero from "../components/sections/Hero"
import Jobs from "../components/sections/Jobs"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Works from "../components/sections/Works"

const IndexPage = ({ data, location }) => {
  return (
    <Layout location={location}>
      <SEO />
      <Hero />
      <AboutMe data={data.about} />
      <Jobs data={data.jobs} />
      <Education data={data.education} />
      <Works data={data.projects} />
    </Layout>
  )
}

IndexPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export const pageQuery = graphql`
  query {
    about: mdx(fileAbsolutePath: { regex: "/about/" }) {
      frontmatter {
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 700, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
    jobs: allMdx(
      filter: { fileAbsolutePath: { regex: "/jobs/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            company
            url
            location
            range
          }
          body
        }
      }
    }
    education: allMdx(
      filter: { fileAbsolutePath: { regex: "/education/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            company
            url
            location
            range
          }
          body
        }
      }
    }
    projects: allMdx(
      filter: { fileAbsolutePath: { regex: "/projects/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            path
            tags
            featuredImgAlt
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
