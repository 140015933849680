import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import { Link } from "gatsby"
import Img from "gatsby-image"

import Label from "../Label"

import styles from "./styles.module.css"

const Image = ({ alt, path, imageUrl }) => {
  return (
    <Link className={styles.image} to={path}>
      <Img
        alt={alt}
        className="w-full max-w-full align-middle relative"
        fluid={imageUrl}
      />
    </Link>
  )
}

Image.propTypes = {
  alt: PropTypes.string,
  path: PropTypes.string,
  imageUrl: PropTypes.object,
}

const ProjectSummary = ({
  company,
  title,
  path,
  tags,
  featuredImgAlt,
  featuredImage,
  excerpt,
}) => {
  return (
    <article aria-label={title} className={styles.project}>
      <div className={styles.content}>
        {company && (
          <h5
            className={classnames(
              styles.subtitle,
              "text-sm font-normal mt-2 font-mono text-teal-600"
            )}
          >
            {company}
          </h5>
        )}
        <h2
          className={classnames(
            styles.title,
            "font-bold text-2xl mx-0 mt-0 mb-4"
          )}
        >
          <Link to={path}>{title}</Link>
        </h2>
        <div className={classnames(styles.excerpt, "p-6 text-base radius-3")}>
          <p>{excerpt}</p>
        </div>
        <ul
          className={classnames(
            styles.list,
            "relative flex flex-wrap p-0 list-none justify-start mt-8 mx-0 mb-12"
          )}
          aria-label="List of technologies"
        >
          {tags.map(tag => (
            <Label tag="li" className="mr-4" key={tag}>
              {tag}
            </Label>
          ))}
        </ul>
      </div>
      {featuredImage && (
        <Image
          alt={featuredImgAlt}
          path={path}
          imageUrl={featuredImage.childImageSharp.fluid}
        />
      )}
    </article>
  )
}

ProjectSummary.propTypes = {
  title: PropTypes.string,
  company: PropTypes.string,
  path: PropTypes.string,
  tags: PropTypes.array,
  excerpt: PropTypes.string,
  featuredImgAlt: PropTypes.string,
  featuredImage: PropTypes.shape({
    childImageSharp: PropTypes.object,
  }),
}

export default ProjectSummary
