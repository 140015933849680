import React from "react"
import PropTypes from "prop-types"

import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"

import Section from "../../Section"

import "./styles.css"

const ProfileImage = ({ src }) => {
  return (
    <div aria-hidden className="about-image relative">
      <Img fluid={src} alt="Avatar" />
    </div>
  )
}

ProfileImage.propTypes = {
  src: PropTypes.object,
}

const AboutMe = ({ data }) => {
  const { frontmatter, body } = data

  return (
    <Section aria-labelledby="about-title" id="about">
      <Section.Content className="max-w-4xl my-0 mx-auto">
        <Section.Title id="about-title">{frontmatter.title}</Section.Title>
        <div className="flex-col md:flex-row w-full flex items-start justify-between">
          <div className="text flex-grow w-full md:w-2/3 max-w-lg">
            <MDXRenderer>{body}</MDXRenderer>
          </div>
          <div className="block w-full md:w-auto flex-grow md:ml-8 md:mt-0 my-12 mx-auto">
            <ProfileImage
              src={frontmatter.featuredImage.childImageSharp.fluid}
            />
          </div>
        </div>
      </Section.Content>
    </Section>
  )
}

AboutMe.propTypes = {
  data: PropTypes.shape({
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
      featuredImage: PropTypes.object,
    }),
    body: PropTypes.string,
  }),
}

export default AboutMe
