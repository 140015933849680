import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import MailIcon from "feather-icons/dist/icons/mail.svg"
import GithubIcon from "feather-icons/dist/icons/github.svg"
import LinkedInIcon from "feather-icons/dist/icons/linkedin.svg"
import TwitterIcon from "feather-icons/dist/icons/twitter.svg"

import Section from "../../Section"

import { useStaticQuery, graphql } from "gatsby"

import styles from "./styles.module.css"

const ListItem = ({ href, children, title, ...props }) => (
  <li
    className={classnames(
      styles.item,
      "inline-flex flex-shrink flex-grow-0 mx-0 md:mx-4 justify-center w-2/6 md:w-auto md:my-0 mt-4"
    )}
  >
    <a
      className="flex flex-col items-center text-center p-4"
      aria-label={title}
      href={href}
      rel="noopener noreferrer"
      {...props}
    >
      {children}
      <span className={classnames(styles.label, "hidden md:block")}>
        {title}
      </span>
    </a>
  </li>
)

ListItem.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
}

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          github
          twitter
          linkedin
        }
      }
    }
  `)

  const [animated, setAnimation] = useState(false)

  useEffect(() => {
    setAnimation(true)
  }, [])

  return (
    <Section
      className={classnames(
        styles.hero,
        "relative",
        "flex",
        "flex-col",
        "justify-center",
        "text-center",
        "items-center"
      )}
    >
      <div
        className={classnames(
          "transition-transform delay-300 duration-700 ease-in-out transform",
          {
            "translate-y-0": animated,
            "translate-y-20": !animated,
          }
        )}
      >
        <h1
          aria-label="Albert Fernández, developer"
          className={classnames("text-3xl", "leading-loose", "font-mono")}
        >
          <span>albert fernández</span>
          <span className={classnames("ml-0", "md:ml-4", "block", "md:inline")}>
            {"{ developer }"}
          </span>
        </h1>
      </div>
      <ul
        aria-label="Social links"
        className={classnames(
          "transition-opacity mt-2 flex flex-wrap justify-center list-none duration-700 delay-500 ease-in-out",
          {
            "opacity-1": animated,
            "opacity-0": !animated,
          }
        )}
      >
        <ListItem title="Email" href={data.site.siteMetadata.email}>
          <MailIcon />
        </ListItem>
        <ListItem
          title="LinkedIn"
          href={data.site.siteMetadata.linkedin}
          target="_blank"
        >
          <LinkedInIcon />
        </ListItem>
        <ListItem
          title="Github"
          href={data.site.siteMetadata.github}
          target="_blank"
        >
          <GithubIcon />
        </ListItem>
        <ListItem
          title="Twitter"
          href={data.site.siteMetadata.twitter}
          target="_blank"
        >
          <TwitterIcon />
        </ListItem>
      </ul>
    </Section>
  )
}

export default Hero
