import React from "react"
import PropTypes from "prop-types"

import classnames from "classnames"

import styles from "./styles.module.css"

const Label = ({ children, className, tag }) =>
  React.createElement(
    tag,
    {
      className: classnames(
        styles.label,
        "font-mono",
        "text-sm",
        "mb-4",
        "whitespace-no-wrap",
        className
      ),
    },
    children
  )

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
}

Label.defaultProps = {
  tag: "label",
}

export default Label
