import React, { useState, useRef, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import { MDXRenderer } from "gatsby-plugin-mdx"

import OutboundLink from "../../Link"
import Section from "../../Section"

import styles from "./styles.module.css"

const Tab = ({ id, title, children, active, onClick }) => {
  return (
    <li
      id={`tab-${id}`}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault()
          onClick()
        }
      }}
      role="tab" // eslint-disable-line
      aria-controls={`panel-${id}`}
      aria-selected={active}
      title={title}
      tabIndex={active ? 0 : -1}
      className={classnames(
        styles.button,
        "bg-transparent",
        "border-b-2",
        "border-solid",
        "duration-100",
        "ease-in",
        "flex",
        "font-mono",
        "h-12",
        "items-center",
        "md:border-b-0",
        "md:border-gray-600",
        "md:border-l-2",
        "md:text-left",
        "pb-1",
        "pt-0",
        "px-6",
        "text-center",
        "text-sm",
        "transition",
        "w-full",
        {
          [styles.active]: active,
        }
      )}
    >
      {children}
    </li>
  )
}

Tab.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  children: PropTypes.node,
  active: PropTypes.bool,
  onClick: PropTypes.func,
}

const TabContent = ({ id, active, children }) => {
  return (
    <div
      id={`panel-${id}`}
      aria-labelledby={`tab-${id}`}
      className="relative flex-grow w-full h-auto pt-2 pl-0 md:pl-12 outline-none"
      hidden={!active}
      role="tabpanel"
    >
      {children}
    </div>
  )
}

TabContent.propTypes = {
  id: PropTypes.number,
  children: PropTypes.node,
  active: PropTypes.bool,
}

export const Title = ({ title, company, url }) => {
  return (
    <h2 className={classnames(styles.company, "text-2xl font-bold mb-4")}>
      <span>{title}</span>
      <span className={styles.at}>&nbsp;@&nbsp;</span>
      <OutboundLink href={url}>{company}</OutboundLink>
    </h2>
  )
}

Title.propTypes = {
  company: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}

export const Subtitle = ({ range, location, className }) => {
  return (
    <h3
      className={classnames(
        styles.range,
        "font-mono text-sm font-normal inline-flex justify-between w-full",
        className
      )}
    >
      <span>{range}</span>
      <span>{location}</span>
    </h3>
  )
}

Subtitle.propTypes = {
  range: PropTypes.string,
  location: PropTypes.string,
  className: PropTypes.string,
}

export const Content = ({ children }) => {
  return (
    <div className={styles.content}>
      <MDXRenderer>{children}</MDXRenderer>
    </div>
  )
}

Content.propTypes = {
  children: PropTypes.node,
}

const Jobs = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [tabFocus, setTabFocus] = useState(null)
  const ref = useRef()

  const focusTab = useCallback(() => {
    const children = [...ref.current.children].filter(node => {
      return node.tagName === "LI"
    })

    if (children[tabFocus]) {
      const element = children[tabFocus]
      element.focus()
    } else {
      if (tabFocus >= children.length) {
        setTabFocus(0)
      }

      if (tabFocus < 0) {
        setTabFocus(children.length - 1)
      }
    }
  }, [tabFocus, setTabFocus])

  const onKeyDown = e => {
    if (e.keyCode === 40) {
      e.preventDefault()
      setTabFocus(tabFocus + 1)
    } else if (e.keyCode === 38) {
      e.preventDefault()
      setTabFocus(tabFocus - 1)
    }
  }

  useEffect(() => {
    focusTab()
  }, [tabFocus, focusTab])

  return (
    <Section id="jobs" className="jobs">
      <Section.Content className="max-w-4xl my-0 mx-auto">
        <Section.Title>{`Where I've worked`}</Section.Title>
        <div className="flex w-full md:flex-row flex-col">
          <ul
            ref={ref}
            className={classnames(
              styles.list,
              "flex md:block relative w-full md:w-56 p-0 m-0 list-none md:mb-0 mb-8"
            )}
            role="tablist" // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
            aria-label="Companies"
            onKeyDown={onKeyDown}
          >
            {data.edges.map(({ node: { frontmatter } }, i) => (
              <Tab
                id={i}
                key={i}
                active={activeTab === i}
                onClick={() => setActiveTab(i)}
                title={frontmatter.company}
              >
                <span>{frontmatter.company}</span>
              </Tab>
            ))}
            <span
              className={classnames(
                styles.border,
                "md:block hidden absolute top-0 left-0 transition-transform duration-100 h-12"
              )}
              style={{
                transform: `translateY(${activeTab * 48}px)`,
              }}
            />
          </ul>
          {data.edges.map(({ node: { frontmatter, body } }, i) => {
            return (
              <TabContent key={i} id={i} active={activeTab === i}>
                <Title
                  title={frontmatter.title}
                  url={frontmatter.url}
                  company={frontmatter.company}
                />
                <Subtitle
                  className="mb-12"
                  range={frontmatter.range}
                  location={frontmatter.location}
                />
                <Content>{body}</Content>
              </TabContent>
            )
          })}
        </div>
      </Section.Content>
    </Section>
  )
}

Jobs.propTypes = {
  data: PropTypes.shape({
    edges: PropTypes.array,
  }),
}

export default Jobs
