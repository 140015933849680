import React from "react"
import PropTypes from "prop-types"

import Section from "../../Section"
import Project from "../../ProjectSummary"

import "./styles.css"

const Works = ({ data }) => {
  return (
    <Section id="works" className="works max-h-full">
      <Section.Content className="max-w-4xl my-0 mx-auto">
        <Section.Title>Works</Section.Title>
        <div className="projects w-full">
          {data.edges.map(({ node: { frontmatter, excerpt } }, i) => (
            <Project key={i} {...frontmatter} excerpt={excerpt} />
          ))}
        </div>
      </Section.Content>
    </Section>
  )
}

Works.propTypes = {
  data: PropTypes.object,
}

export default Works
