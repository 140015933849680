import React from "react"
import PropTypes from "prop-types"

import Section from "../../Section"
import { Title, Subtitle, Content } from "../Jobs"

const Education = ({ data }) => {
  return (
    <Section id="education">
      <Section.Content className="max-w-4xl my-0 text-left">
        <Section.Title>{`Where I've studied`}</Section.Title>
        {data.edges.map(({ node }) => {
          return (
            <div
              key={node.frontmatter.url}
              className="flex flex-col w-full mb-8"
            >
              <Title
                title={node.frontmatter.title}
                company={node.frontmatter.company}
                url={node.frontmatter.url}
              />
              <Subtitle
                className="mb-8"
                range={node.frontmatter.range}
                location={node.frontmatter.location}
              />
              <Content>{node.body}</Content>
            </div>
          )
        })}
      </Section.Content>
    </Section>
  )
}

Education.propTypes = {
  data: PropTypes.object,
}

export default Education
